
import { Interval, toDate, roundToNearestMinutes, isAfter, addMinutes, isBefore, isEqual } from 'date-fns';

export class DateHelper {


  static eachMinutesOfInterval(interval: Interval, step: number = 1): Date[] {

    if (step < 1 || isNaN(step)) {
      throw new RangeError('`options.step` must be a number greater than 1');
    }

    interval = interval || { start: 0, end: 0 };
    const startDate = toDate(interval.start);
    const endDate = toDate(interval.end);

    // Throw an exception if startDate is after endDate or if any date is `Invalid Date`
    if (isAfter(startDate, endDate)) {
      throw new RangeError('Invalid interval');
    }

    let currentDate = roundToNearestMinutes(startDate, { nearestTo: step });
    while (isBefore(currentDate, startDate)) {
      currentDate = addMinutes(currentDate, step);
    }

    const dates: Date[] = [];
    while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
      dates.push(toDate(currentDate));
      currentDate = addMinutes(currentDate, step);
    }

    return dates;
  }

  static isBetween(date: Date, startDate: Date, endDate: Date): boolean {
    return isAfter(date, startDate) && isBefore(date, endDate);
  }

}
