<div class="product-recap">
  <div *ngFor="let step of cartProduct.steps"
       fxLayout="column">
    <div *ngFor="let stepCartProduct of step.products">
      <div fxLayout="row"
           fxLayoutGap="5px">
        <!-- Image -->
        <div *ngIf="step.step.isImageDisplayEnabled && stepCartProduct.product.image?.fullUrl"
             class="product-recap-image"
             [ngStyle]="{'background-image': 'url(\'' + (stepCartProduct.product.image?.fullUrl) + '\')'}"></div>
        <!-- Quantity-->
        <div *ngIf="stepCartProduct.quantity > 1">
          {{ stepCartProduct.quantity }}x
        </div>
        <!-- Nom produit-->
        <div>{{ stepCartProduct.product.name }}</div>
        <!-- Prix en supplement-->
        <div fxLayout="row"
             *ngIf="stepCartProduct.totalPrice !== 0"
             fxFlexAlign="end">
          ({{ stepCartProduct.totalPrice | sign }}<app-price [price]="stepCartProduct.totalPrice"
                     [floating]="false"
                     [currencyCode]="currencyCode"></app-price>)
        </div>
      </div>

      <app-product-recap [cartProduct]="stepCartProduct"></app-product-recap>
    </div>
  </div>
</div>
